exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jetzt-reinhoeren-js": () => import("./../../../src/pages/jetzt-reinhoeren.js" /* webpackChunkName: "component---src-pages-jetzt-reinhoeren-js" */),
  "component---src-pages-jetzt-testen-js": () => import("./../../../src/pages/jetzt-testen.js" /* webpackChunkName: "component---src-pages-jetzt-testen-js" */)
}

